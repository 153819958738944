<template>
	<div>
		<transition name="fade">
			<Message v-if="error" :message="error" type="error" />
		</transition>

		<transition name="fade">
			<Message v-if="status" :message="status" type="info" />
		</transition>

		<Message v-if="loading" message="loading" type="info" />

		<template v-else>
			<table>
				<thead>
					<th class="user">User</th>
					<th>Admin</th>
					<th>Group</th>
				</thead>

				<tr v-for="user of users" :key="user.matrix">
					<td
						class="user"
						:title="user.matrix"
					>
						{{ getLocalPart(user.matrix) }}
					</td>

					<td class="center">
						<input
							v-model="user.isAdmin"
							type="checkbox"
							:disabled="!!status"
						>
					</td>

					<td class="center">
						<select v-model="user.group" :disabled="!!status">
							<option
								v-for="group of groups"
								:key="group"
								:value="group"
								:selected="group === user.group"
							>
								{{ group }}
							</option>
						</select>
					</td>
				</tr>
			</table>

			<br>

			<div class="navigation">
				<Button @click="back">
					Back
				</Button>

				<Button @click="updateUsers">
					Update
				</Button>
			</div>
		</template>
	</div>
</template>

<script>
import api from "@/api";

import SubmitButton	from "@/components/SubmitButton.vue";
import Message	from "@/components/Message.vue";
import Button	from "@/components/Button.vue";

export default {
	name: "Users",

	components: {
		Message,
		Button
	},

	data () {
		return {
			error: undefined,
			status: undefined,
			loading: true,

			users: [],

			groups: []
		};
	},

	async mounted () {
		try {
			this.users = await api.getUsers();
			this.groups = Object.keys(await api.getGroups());
		} catch (error) {
			this.error = error.message;
			console.error(error);
		}

		// Update status
		this.loading = false;
	},

	methods: {
		async updateUsers () {
			this.status = "Updating";
			this.error = undefined;

			try {
				await api.setUsers(this.users);
			} catch (error) {
				this.error = error.message;
				console.error(error);
			}

			this.status = undefined;
		},

		back () {
			this.$router.push({
				name: "Admin"
			});
		},

		getLocalPart (matrix) {
			return matrix.split(":")[0].replace("@", "");
		}
	}
};
</script>

<style scoped>
table {
	border-collapse: collapse;
	width: 100%;
}

td {
	padding: 10px;
}

td, th {
	max-width: 200px;
	overflow: hidden;
	text-overflow: ellipsis;
}

td.user {
	width: 200px;
}

tr:nth-child(even) {
	background-color: #d9d9d9;
}

.center {
	text-align: center;
}

.navigation {
	display: flex;
}

.navigation > * {
	width: 40%;
	margin: auto;
}

select {
	display: block;
	width: 100%;
	box-sizing: border-box;
	padding: 5px 0;
	font-size: 12pt;
	background-color: white;
	border-radius: 6px;
	border: 1px solid #999;


	/* Arrow css */
	-webkit-appearance: none;
	-moz-appearance: none;
	background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
	background-repeat: no-repeat;
	background-position-x: 100%;
	background-position-y: 5px;
	border: 1px solid #dfdfdf;
	margin-right: 2rem;
}
</style>
